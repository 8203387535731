<template>
  <v-row class="pa-4">
    <v-col cols="12" style="border-radius: 0.2em; background-color: rgb(250, 250, 250); padding: 20px;">
      <v-text-field
        v-model="item.address1"
        :rules="fieldProps.address1.props.required ? requireRules : []"
        v-bind="fieldProps.address1.props"
      >
        <template v-slot:label>
          {{ $t("locations.fields.address1") }}
          <strong v-if="fieldProps.address1.props.required" class="red--text">*</strong>
        </template>
      </v-text-field>
    </v-col>
    <v-col cols="12" style="border-radius: 0.2em; background-color: rgb(250, 250, 250); padding: 20px;">
      <v-text-field
        v-model="item.address2"
        :rules="fieldProps.address2.props.required ? requireRules : []"
        v-bind="fieldProps.address2.props"
      >
        <template v-slot:label>
          {{ $t("locations.fields.address2") }}
          <strong v-if="fieldProps.address2.props.required" class="red--text">*</strong>
        </template>
      </v-text-field>
    </v-col>
    <v-col cols="12" md="6" style="border-radius: 0.2em; background-color: rgb(250, 250, 250); padding: 20px;">
      <v-autocomplete
        v-model="item.country"
        :items="countries"
        item-text="name"
        item-value="name"
        :rules="fieldProps.country.props.required ? requireRules : []"
        @change="onCountryChange"
        @click:clear="onCountryClear"
        v-bind="fieldProps.country.props"
        :clearable="true"
        :disabled="loadingCountries"
      >
        <template v-slot:label>
          {{ $t("locations.fields.country") }}
          <strong v-if="fieldProps.country.props.required" class="red--text">*</strong>
        </template>
        <template v-slot:append>
          <span v-if="loadingCountries" class="field-value loading-placeholder mt-1">
            <v-progress-circular indeterminate color="primary" size="20" width="3"></v-progress-circular>
          </span>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="12" md="6" style="border-radius: 0.2em; background-color: rgb(250, 250, 250); padding: 20px;">
      <v-autocomplete
        v-model="item.state"
        :items="states"
        item-text="name"
        item-value="name"
        :rules="fieldProps.state.props.required ? requireRules : []"
        @change="onStateChange"
        @click:clear="onStateClear"
        v-bind="fieldProps.state.props"
        :clearable="true"
        :disabled="loadingStates || !item.country"
      >
        <template v-slot:label>
          {{ $t("locations.fields.state") }}
          <strong v-if="fieldProps.state.props.required" class="red--text">*</strong>
        </template>
        <template v-slot:append>
          <span v-if="loadingStates" class="field-value loading-placeholder mt-1">
            <v-progress-circular indeterminate color="primary" size="20" width="3"></v-progress-circular>
          </span>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="12" md="6" style="border-radius: 0.2em; background-color: rgb(250, 250, 250); padding: 20px;">
      <v-autocomplete
        v-model="item.city"
        :items="cities"
        item-text="name"
        item-value="name"
        :rules="fieldProps.city.props.required ? requireRules : []"
        v-bind="fieldProps.city.props"
        :clearable="true"
        :disabled="loadingCities || !item.state"
      >
        <template v-slot:label>
          {{ $t("locations.fields.city") }}
          <strong v-if="fieldProps.city.props.required" class="red--text">*</strong>
        </template>
        <template v-slot:append>
          <span v-if="loadingCities" class="field-value loading-placeholder mt-1">
            <v-progress-circular indeterminate color="primary" size="20" width="3"></v-progress-circular>
          </span>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="12" md="6" style="border-radius: 0.2em; background-color: rgb(250, 250, 250); padding: 20px;">
      <v-text-field
        v-model="item.zipCode"
        :rules="fieldProps.zipCode.props.required ? requireRules : []"
        v-bind="fieldProps.zipCode.props"
      >
        <template v-slot:label>
          {{ $t("locations.fields.zipCode") }}
          <strong v-if="fieldProps.zipCode.props.required" class="red--text">*</strong>
        </template>
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import MapsServices from '@/services/GeolocationService';
import { mapGetters } from "vuex"

export default {
  props: {
    fieldProps: {
      type: Object,
      default: () => ({
        address1: { props: {} },
        address2: { props: {} },
        country: { props: {} },
        state: { props: {} },
        city: { props: {} },
        zipCode: { props: {} }
      })
    },
    item: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    valid: true,
    countries: [],
    states: [],
    cities: [],
    loadingCountries: false,
    loadingStates: false,
    loadingCities: false,
    errorCountries: false,
    errorStates: false,
    errorCities: false
  }),
  async mounted() {
    await this.fetchCountries();
    this.item.country = this.countries.find(c => c.name === this.item.country);
    console.log('fetchCountries')
    if (this.item.country) {
      console.log('fetchStates')
      await this.fetchStates();
      this.item.state = this.states.find(s => s.name === this.item.state);
      if (this.item.state) {
        console.log('fetchCities')
        await this.fetchCities();
        this.item.city = this.cities.find(s => s.name === this.item.city);
      }
    }
  },
  computed: {
    ...mapGetters({
      requireRules: 'general/requireRules',
      selectRules: 'general/selectRule'
    }),
    selectedCountry: {
      get() {
        return this.item.country;
      },
      set(newValue) {
        this.item.country = newValue;
        this.fetchStates();
      }
    },
    selectedState: {
      get() {
        return this.item.state;
      },
      set(newValue) {
        this.item.state = newValue;
        this.fetchCities();
      }
    }
  },
  methods: {
    async fetchCountries() {
      this.loadingCountries = true;
      this.errorCountries = false;
      try {
        const response = await MapsServices.fetchAllCountries();
        this.countries = response.data.countries;
      } catch (error) {
        this.errorCountries = true;
        console.error("Failed to fetch countries", error);
      } finally {
        this.loadingCountries = false;
      }
    },

    async fetchStates() {
      if (!this.item.country) return;
      this.loadingStates = true;
      this.errorStates = false;
      try {
        const response = await MapsServices.fetchStatesByCountry(this.item.country.iso2);
        this.states = response.data.states;
      } catch (error) {
        this.errorStates = true;
        console.error("Failed to fetch states", error);
      } finally {
        this.loadingStates = false;
      }
    },

    async fetchCities() {
      if (!this.item.state) return;
      this.loadingCities = true;
      this.errorCities = false;
      try {
        const response = await MapsServices.fetchCitiesOfState(this.item.country.iso2, this.item.state.iso2);
        this.cities = response.data.cities;
      } catch (error) {
        this.errorCities = true;
        console.error("Failed to fetch cities", error);
      } finally {
        this.loadingCities = false;
      }
    },

    onCountryChange(newCountry) {
      this.resetStates();
      this.item.country = newCountry;
      this.fetchStates();
    },

    onStateChange(newState) {
      this.resetCities();
      this.item.state = newState;
      this.fetchCities();
    },

    onCountryClear() {
      this.item.country = null;
      this.resetStates()
    },

    onStateClear() {
      this.item.state = null;
      this.resetCities()
    },

    resetStates() {
      this.item.state = null;
      this.states = [];
      this.resetCities();
    },

    resetCities() {
      this.item.city = null;
      this.cities = [];
    },

    getCountryName(item) {
      if (typeof item === 'string' )
        item = { name: item };

      if (item) {
        const country = this.countries.find(c => c.name === item.name);
        return country ? country.name ? country.name : country : null;
      }

      return '-'
    },
    getStateName(item) {
      if (typeof item === 'string' )
        item = { name: item };

      if (item) {
        const state = this.states.find(s => s.name === item.name);
        return state ? state.name ? state.name : state : null;
      }

      return '-'
    },
    getCityName(item) {
      if (typeof item === 'string' )
        item = { name: item };

      if (item) {
        const city = this.cities.find(c => c.name === item.name);
        return city ? city.name ? city.name : city : null;
      }

      return '-'
    }
  },

  beforeDestroy() {
    console.log("AddressSection Destroyed");
  }
};
</script>


<style scoped>
.display-field {
  padding: 8px;
  background-color: #f5f5f5;
  border-radius: .2rem;
  display: flex;
  flex-direction: column;
}
.field-label {
  font-weight: bold;
  margin-right: 5px;
}
.field-value {
  font-size: 16px;
  margin-bottom: 5px;
}
.loading-placeholder {
  display: flex;
  align-items: center;
  height: 20px; /* Alinea el tamaño del indicador con el tamaño de la fuente */
}
</style>

